import { httpClient } from './axiosClients';

export function getUser() {
  return httpClient.get('/api/user');
}

export function getIsAuthenticated() {
  return httpClient.get('/api/wc/is-authenticated');
}

export function getCities({ params }) {
  return httpClient.get('/api/city', { params });
}

export function createEstimateRequest(payload, headers) {
  return httpClient.post('/api/wc/leads/property-estimate-requests', payload, {
    headers
  });
}

export function getSocialLoginUrls(params) {
  return httpClient.get('/api/social-login-urls', { params });
}

export function createCallMeBackRequest(payload) {
  return httpClient.post('/api/leads/call-me-back', payload);
}

export function getSurvey(surveyId) {
  return httpClient.get(`/api/surveys/${surveyId}`);
}

export function updateSurvey(surveyId, payload) {
  return httpClient.patch(`/api/surveys/${surveyId}`, payload);
}

export function getPropertyTypes(params = { flat: true }) {
  return httpClient.get('/api/property-types', { params });
}
