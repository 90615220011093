import Vue from 'vue';
import i18n from '@/i18n';

import { getMapboxStreetName } from '@/utils/helpers';

import { getCities } from '@/services/apiService';
import { mapboxAddressSearch } from '@/services/mapbox';

Vue.component('mapbox-address-option-render', {
  functional: true,
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  render(createElement, context) {
    const properties = context.props.value?.properties;
    const address = properties?.full_address || properties?.name || '';
    return createElement('span', address);
  }
});

const types = {
  city: {
    textLoading: i18n.t('loading_cities'),
    textNoResultsFound: i18n.t('no_cities_found'),
    displayFormat(city) {
      // zip_code can be an empty string in case of provinces and regions
      return [city.zip_code, city[`name_${i18n.locale}`]]
        .filter(value => value)
        .join(' - ');
    },
    fetchResults: getCities
  },
  address: {
    textLoading: 'Adressen laden',
    textNoResultsFound: 'Adres niet gevonden',
    optionsKey: 'features',
    displayFormat: getMapboxStreetName,
    optionRender: 'mapbox-address-option-render',
    allowText: true,
    fetchResults: mapboxAddressSearch,
    async selectionCallback(result) {
      try {
        if (!result?.properties?.context) return null;
        const { address, street, postcode, place } = result.properties.context;

        // These params are sent to IAM, so we always send only dutch.
        // We need IAM city to save the id of in the database.
        const params = {
          zip_code: postcode?.name,
          name: place?.translations?.nl?.name
        };
        const response = await getCities({ params });
        const results = response?.data?.results;
        if (!results.length) {
          throw new Error(
            `Belgische gemeente bestaat niet. Contacteer de IT-helpdesk met de vraag om volgende gemeente aan te maken: <span class="tw-font-bold">${params.zip_code} ${params.name}</span>`
          );
        }
        const city = results[0];

        return { city, street: street?.name, number: address?.address_number };
      } catch (error) {
        console.log(error);
        throw new Error('Fout bij het maken van de stad, probeer het opnieuw.');
      }
    }
  }
};

export function getAutoCompleteType(type) {
  return types[type];
}
