<template>
  <div
    :class="[
      `formulate-input-element formulate-input-element--${context.type} relative`,
      { 'mt-8': multipleSelections.length }
    ]"
    :data-type="context.type"
  >
    <span
      v-if="multiple && multipleSelections.length"
      class="-my-6 text-sm float-right absolute right-0"
    >
      {{ multipleSelections.length }}
      <span>
        {{
          multipleSelections.length === 1
            ? $t('selected_singular')
            : $t('selected_plural')
        }}
      </span>
    </span>
    <!--
      In case of web-components, target does not have the value of the event, so we use `composedPath` instead.
      We need to send the target as an argument to the method because due to debounce, the array becomes empty.
    -->
    <input
      v-model="search"
      type="text"
      v-bind="context.attributes"
      autocomplete="off"
      class="pr-9"
      @input="debouncedLoadResults($event.composedPath()[0])"
      @keydown.enter.prevent="onEnter"
      @keydown.tab.exact="onEnter"
      @keydown.esc.stop="onEsc"
      @keydown.shift.tab.exact="onEsc"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @blur="context.blurHandler"
    />
    <button
      v-if="context.hasValue && !multiple"
      type="button"
      title="Clear"
      class="p-2 absolute right-0 disabled:bg-transparent"
      @click.stop="setResult()"
    >
      <font-awesome-icon :icon="['far', 'times']" />
    </button>
    <ul
      v-show="showResults"
      aria-label="results"
      tabindex="-1"
      class="text-base absolute max-h-48 w-full bg-white rounded-md overflow-y-scroll shadow-card z-20"
    >
      <li v-if="isLoading" key="loading" class="p-2">
        {{ autocomplete.textLoading }}...
      </li>
      <li
        v-for="(result, index) in results"
        v-else-if="results.length"
        :key="`result_${index}`"
        :class="[
          'p-2 cursor-pointer hover:bg-grey-4',
          { 'bg-grey-4': index === arrowCounter }
        ]"
        @mouseenter="arrowCounter = index"
        @click="setResult(result)"
      >
        <slot name="result">
          <component :is="autocomplete.optionRender || 'div'" :value="result">
            {{ getDisplayValue(result) }}
          </component>
        </slot>
      </li>
      <li v-else key="error" class="p-2">
        {{ error ? error : autocomplete.textNoResultsFound }}.
      </li>
    </ul>
    <ul
      v-if="multiple"
      aria-label="selections"
      class="w-full flex flex-wrap gap-x-3"
    >
      <template v-for="(selection, index) in multipleSelections">
        <!-- list items need to be in the DOM for accessibility tools to work. -->
        <li
          v-show="selection.id"
          :key="`selection_${selection.id}`"
          class="my-1 border px-1 rounded"
        >
          <button
            type="button"
            title="Verwijderen"
            aria-label="remove selection"
            tabindex="0"
            class="px-1 text-blank rounded focus:ring-2 focus:ring-primary"
            @click.stop="removeSelection(index)"
          >
            <font-awesome-icon :icon="['far', 'fa-times']" />
          </button>
          <span
            :title="getDisplayValue(selection)"
            class="font-semibold truncate"
          >
            {{ getDisplayValue(selection) }}
          </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { getAutoCompleteType } from '@/forms/autoCompleteTypes';
import debounce from 'lodash/debounce';

export default {
  name: 'BaseInputAutoComplete',
  props: {
    context: {
      type: Object,
      required: true
    },
    autoCompleteType: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showResults: false,
      results: [],
      error: '',
      search: '',
      isLoading: false,
      arrowCounter: -1,
      multipleSelections: []
    };
  },
  computed: {
    model: {
      get() {
        return this.context.model;
      },
      set(val) {
        this.context.model = val;
      }
    },
    autocomplete() {
      return getAutoCompleteType(this.autoCompleteType);
    }
  },
  watch: {
    model(value) {
      if (this.multiple && Array.isArray(value))
        this.multipleSelections = [...value];
      this.search = this.multiple ? '' : this.getDisplayValue(value);
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    debouncedLoadResults: debounce(function(target) {
      this.loadResults(target.value);
    }, 300),

    loadResults(query) {
      this.isLoading = true;
      const trimmedQuery = query.trim();

      const params = { query: trimmedQuery };
      if (this.multiple) {
        params.include_children = 1;
        params.include_custom = 1;
        params.serialize_children = 0;
      }

      this.autocomplete
        .fetchResults({ params })
        .then(response => {
          this.results =
            response.data[this.autocomplete.optionsKey || 'results'];
        })
        .catch(error => {
          this.results = [];
          this.error = error.detail;
        })
        .finally(() => {
          this.isLoading = false;
          this.showResults = true;
          this.arrowCounter = -1;
        });
    },

    async setResult(result = '') {
      if (result && this.multiple) {
        const selections = [...this.multipleSelections];
        selections.push(result);
        const uniqueSelections = selections.filter(
          (selection, index, array) =>
            array.findIndex(el => el.id === selection.id) === index
        );
        this.multipleSelections = uniqueSelections;
      }
      this.model = this.multiple ? this.multipleSelections : result;
      this.results = [];
      this.showResults = false;

      const { selectionCallback } = this.autocomplete;
      if (selectionCallback) {
        const response = await selectionCallback(result);
        this.context.rootEmit('callback', response);
        return response;
      }
    },

    getDisplayValue(value) {
      if (!value) return '';
      if (typeof value === 'object') {
        return this.autocomplete.displayFormat(value);
      }
      return this.autocomplete.allowText ? value : '';
    },

    removeSelection(index) {
      this.multipleSelections.splice(index, 1);
      return this.setResult();
    },

    onArrowDown() {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      const result = this.results[this.arrowCounter];
      if (result) this.setResult(result);
    },
    onEsc() {
      if (!this.search?.length) this.setResult();
      else if (!this.multiple) {
        this.$nextTick(() => {
          if (!this.model && this.autocomplete.allowText) {
            this.setResult(this.search);
          } else {
            this.search = this.getDisplayValue(this.model);
          }
        });
      }
      this.showResults = false;
      this.arrowCounter = -1;
    },

    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.onEsc();
      }
    }
  }
};
</script>
